/**
 * api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.7.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TokenConfigResponse { 
    commissionPercentage: string;
    minMarginPercentage: string;
    sellOrder: TokenConfigResponse.SellOrderEnum;
    excludedStockAmount: number;
}
export namespace TokenConfigResponse {
    export type SellOrderEnum = 'highest_margin' | 'lowest_margin';
    export const SellOrderEnum = {
        HighestMargin: 'highest_margin' as SellOrderEnum,
        LowestMargin: 'lowest_margin' as SellOrderEnum
    };
}


