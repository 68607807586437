/**
 * api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.7.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PreciousMetalTokenTransactionItem { 
    id: string;
    serialNumber: string;
    transactionId: string;
    amount: number;
    inStock: number;
    stockStatus: PreciousMetalTokenTransactionItem.StockStatusEnum;
    price: number;
    profit: number;
    transactionStatus: string;
    commissionPercentage: number;
    dateOfPurchase: string;
    supplierName: string;
    storageLocation: string;
    barWeight: number;
    barCost: number;
    isLatest: boolean;
    createdAt: string;
}
export namespace PreciousMetalTokenTransactionItem {
    export type StockStatusEnum = 'in_stock' | 'out_of_stock' | 'below_margin';
    export const StockStatusEnum = {
        InStock: 'in_stock' as StockStatusEnum,
        OutOfStock: 'out_of_stock' as StockStatusEnum,
        BelowMargin: 'below_margin' as StockStatusEnum
    };
}


