/**
 * api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.7.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type AuditPageRequestActionsInnerAnyOf3 = 'forgot_password';

export const AuditPageRequestActionsInnerAnyOf3 = {
    ForgotPassword: 'forgot_password' as AuditPageRequestActionsInnerAnyOf3
};

