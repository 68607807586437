/**
 * api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.7.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TransactionItemExpandedType { 
    transactionId: string;
    firstName: string;
    lastName: string;
    paymentMethod: TransactionItemExpandedType.PaymentMethodEnum;
    status: string;
    fromAmount: number;
    fromCurrency: string;
    toAmount: number;
    toCurrency: string;
    fee: string;
    createdAt: string;
}
export namespace TransactionItemExpandedType {
    export type PaymentMethodEnum = 'demo' | 'coinify' | 'card' | 'eft' | 'wallet' | 'voucher';
    export const PaymentMethodEnum = {
        Demo: 'demo' as PaymentMethodEnum,
        Coinify: 'coinify' as PaymentMethodEnum,
        Card: 'card' as PaymentMethodEnum,
        Eft: 'eft' as PaymentMethodEnum,
        Wallet: 'wallet' as PaymentMethodEnum,
        Voucher: 'voucher' as PaymentMethodEnum
    };
}


