/**
 * api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.7.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface IncreasePreciousMetalRequest { 
    preciousMetalId: string;
    serialNumber: string;
    amount: number;
    dateOfPurchase: string;
    supplierName: string;
    storageLocation: string;
    barWeight: number;
    barCost: number;
}

