/**
 * api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.7.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SearchUsersWithPaginationRequest { 
    term: string;
    page: number;
    size: number;
    roles: Array<SearchUsersWithPaginationRequest.RolesEnum>;
}
export namespace SearchUsersWithPaginationRequest {
    export type RolesEnum = 'admin_full' | 'admin_view' | 'asset_holder';
    export const RolesEnum = {
        AdminFull: 'admin_full' as RolesEnum,
        AdminView: 'admin_view' as RolesEnum,
        AssetHolder: 'asset_holder' as RolesEnum
    };
}


